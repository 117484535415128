<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("employee_ot.title") }}</h1>
      </div>
      <v-breadcrumbs-item class="breadcrumbs-hover" v-if="$route.name === 'employee_ot.edit'">
        <span v-show="true">/</span>
        <span>{{ $t("employee_ot.edit") }}</span>
      </v-breadcrumbs-item>
    </div>
    <hr />
    <br />
    <div class="section-action">
      <v-row>
        <v-col cols="12" md="10" lg="12">
          <v-row >
            <v-col cols="12" md="3" lg="3">
              <label class="label-input">{{ $t("Employee.dept") }}</label>
              <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="listDepartment"
                hide-selected v-model="department_id" @change="searchFilterItem">
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="3" lg="3">
              <label class="label-input">{{ $t("Employee.branch") }}</label>
              <v-autocomplete filled dense item-text="branch_name" item-value="id" outlined :items="listBranch"
                hide-selected v-model="branch_id" @change="searchFilterItem">
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.branch_name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2" lg="2">
              <label class="label-input">{{ $t("employee_ot.date") }}</label>
              <DatePicker class="date-time" style="width: 100%" type="date" v-model="date" valueType="format"
                slot="activator" :append-to-body="true" name="founding_date" @change="searchFilterItem()"></DatePicker>
            </v-col>
            <v-col cols="12" md="3" lg="3">
              <label class="label-input">{{
                $t("employee_ot.search_title")
              }}</label>
              <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense v-model="searchItem"
                prepend-inner-icon="mdi-magnify" :placeholder="$t('Search.search')" @keypress.enter="searchFilterItem">
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" lg="1">
              <div class="btn-new-create-action">
                <v-btn class="btn-create lfont btn" color="info" @click="onCreate" style="margin-top: 1rem;">
                  {{ $t("employee_ot.create") }}</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>

      </v-row>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listEmployeeOt.length > 0">
          <thead>
            <tr>
              <th class="text-left">No</th>
              <th class="text-left">
                {{ $t("employee_ot.table.emp_number") }}
              </th>
              <th class="text-left">
                {{ $t("employee_ot.table.emp_name") }}
              </th>
              <th class="text-left">
                {{ $t("employee_ot.table.branch") }}
              </th>
              <th class="text-left">
                {{ $t("employee_ot.table.dept") }}
              </th>
              <th class="text-left">
                {{ $t("employee_ot.table.date") }}
              </th>
              <!-- <th class="text-left">
                {{ $t("employee_ot.table.emp_run_time") }}
              </th> -->
              <!-- <th class="text-left">
                {{ $t("employee_ot.table.qty_hour") }}
              </th> -->
              <th class="text-left">
                {{ $t("employee_ot.table.status") }}
              </th>
              <th class="text-end"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in listEmployeeOt" :key="idx">
              <td>{{ idx + 1 }}</td>
              <td>{{ item.employee.emp_number }}</td>
              <td>{{ item.employee.name }} {{ item.employee.surname }}</td>
              <td>{{ item.employee.branch }}</td>
              <td>{{ item.employee.dept }}</td>
              <td>
                <p v-for="(item_date, i) in item.ot_dates">{{ item_date.date }}
                  <span class="text-primary">({{ item_date.start_time }} - {{ item_date.end_time }})</span>
                </p>
              </td>
              <!-- <td>{{ item.check_in }} - {{ item.check_out }}</td> -->
              <!-- <td>
                <span v-if="item.qty.hour > 0">
                  {{ item.qty.hour }} {{ $t("employee_ot.table.hour") }}
                </span>

                <span v-if="item.qty.minute > 0">
                  {{ item.qty.minute }} {{ $t("employee_ot.table.minute") }}
                </span>
              </td> -->
              <td>
                <span v-if="item.ot_status == 'pendding'">{{ item.ot_status }}</span>
                <span v-else-if="item.ot_status == 'approve'" class="text-primary">{{ item.ot_status }}</span>
                <span v-else class="text-danger">{{ item.ot_status }}</span>
              </td>

              <td class="text-end">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined v-bind="attrs" v-on="on">
                      <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <!-- <v-list-item>
                      <v-list-item-title class="btn-edit" @click="onEdit(item.id)">Edit</v-list-item-title>
                    </v-list-item> -->
                    <v-list-item>
                      <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">{{ $t("action.delete") }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else />
        <ModalDelete fullscreen title="Edit Employee Leave">
          <template v-slot="{ close }">
            <Delete :employee_ot_id="employee_ot_id" @close="close" @success="fetchEmployeeOt()" />
          </template>
        </ModalDelete>
        <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
          @paginate="fetchEmployeeOt">
        </Pagination>
        <Loading v-if="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Pagination from "../../../components/Paginate/Pagination";
import defaultTableNoResult from "@/components/defaultTableNoResult";
import Loading from "@/components/Loading";
import Delete from "./Delete";

import "vue2-datepicker/index.css";
export default {
  components: {
    DatePicker,
    Pagination,
    defaultTableNoResult,
    Loading,
    Delete,
  },
  data() {
    return {
      listBranch: [],
      branch_id: '',
      department_id: '',
      listDepartment: [],
      offset: 10,
      pagination: {},
      employeeOt: {},
      per_page: 10,
      isLoading: true,
      listEmployeeOt: [],
      searchItem: "",
      isLoading: true,
      employee_ot_id: "",
      date: "",
    };
  },
  methods: {
    searchFilterItem() {
      this.fetchEmployeeOt();
      this.pagination.current_page = 1;
    },
    onCreate() {
      this.$router
        .push({
          name: "employee_ot.create",
        })
        .catch(() => { });
    },

    onEdit(item) {
      //this.$store.commit("Employee/EMPLOYEE_ID", item);
      this.$router.push({
        name: "employee_ot.edit",
        params: {
          employee_ot_id: item,
        },
      });
    },

    onDeleted(employee_ot_id) {
      this.employee_ot_id = employee_ot_id;
      this.$store.commit("modalDelete_State", true);
    },
    fetchDepartment() {
      this.$axios.get(`company/list/departments/selected`, {}).then((res) => {
        if (res.status === 200) {
          this.listDepartment = res.data.data;
        }
      });
    },
    fetchBranch() {
      this.$axios
        .get(`company/list/company/branches`)
        .then((res) => {
          if (res) {
            this.listBranch = res.data.companyBranches;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchEmployeeOt() {
      this.isLoading = true;
      this.$axios
        .get(`company/request/ot`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
            department_id: this.department_id,
            branch_id: this.branch_id,
            date: this.date,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.listEmployeeOt = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listEmployeeOt.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchEmployeeOt();
    this.fetchDepartment();
    this.fetchBranch();
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 20px;
}
</style>
